<template>
  <section>
    <b-card>
      <div class="mb-2">
        <b-row>
          <b-col md="7">
            <h3>Set your weekly hours</h3>
          </b-col>
           <b-col md="5">
            <v-select
              id="timezone"
              v-model="selectedTimeZone"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="timeZoneList"
              placeholder="Default Time Zone"
              label="text"
            />
          </b-col>
        </b-row>
      </div>
      <hr />
      <div>
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
        <b-row align-h="end">
            <!-- buttons -->
            <b-col lg="3" md="4" class="pb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click.prevent="updateAvailability"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="mt-1"
                @click.prevent="resetForm"
              >
                Reset
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
          <b-row>
            <b-col><hr/></b-col>
          </b-row>
          <b-row
            v-for="(item, index) in daysOfWeek"
            :id="index"
            :key="item.day"
            ref="row"
            > 
            <b-col md="1" class="mt-1">
              <b-form-group>
                <b-form-checkbox
                  v-model="item.value"
                  name="checkbox"
                  :input-id="`checkbox-day-${index}`"
                >
                  {{ item.day }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="7" v-if="item.value">
              <!-- Row Loop -->
              <b-row
                v-for="(time, indexInterval) in item.intervals"
                :id="`row-${item.day}`"
                :key="time.id"
                ref="row"
              >
                <!-- StartTime -->
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="time interval"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        @input="onChange"
                        vid='email'
                        :id="`start-time-${item.day}-${indexInterval}`"
                        v-model="time.startTime"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="timesList"
                        label="text"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- endTime -->
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="time interval"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        @input="onChange"
                        :id="`endtime-${item.day}-${indexInterval}`"
                        v-model="time.endTime"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="timesList"
                        label="text"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Remove Button -->
                <b-col md="1">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mb-1"
                    @click="removeItem(item, indexInterval)"
                    v-b-tooltip.hover title="Remove Availability Interval"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-25"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="7" class="mt-1" v-else>
               <h5 class="text-muted">Unavailable</h5>
            </b-col>
            <!-- Add Button -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-1"
                @click="repeateAgain(item)"
                v-b-tooltip.hover title="Add Availability Interval"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
              </b-button>
            </b-col>
            <!-- Copy Button
            <b-col md="1" v-if="item.value">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-1"
                @click="copyDay(item)"
                v-b-tooltip.hover title="Copy availability for this day"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="mr-25"
                />
              </b-button>
            </b-col>
            <b-col md="2" v-if="item.value">
              <span class="text-muted">Copy Day To:</span>
              <v-select
                v-model="copiedDay"
                :options="daysList"
              />
            </b-col>-->
            <b-col cols="12">
                <small class="text-danger hidden" :id="`intervalError-${item.day}`">Times overlap with another set of times.</small>
              <hr>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard, BFormInvalidFeedback, BFormCheckbox, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from "moment-timezone"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getAuth } from "firebase/auth";
import { getFirestore, setDoc, doc} from 'firebase/firestore';

export default {
  components: {
    required,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip, Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      userData: null,
      errors: false,
      selectedTimeZone: null,
      timeZoneList: [],
      copiedDay: "SUN",
      daysOfWeek:[{day:'SUN', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'MON', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'TUE', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'WED', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'THU', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'FRI', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'SAT', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]}],
      daysList: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      timesList: ["12:00 AM","12:15 AM","12:30 AM","12:45 AM",
                  "01:00 AM","01:15 AM","01:30 AM","01:45 AM",
                  "02:00 AM","02:15 AM","02:30 AM","02:45 AM",
                  "03:00 AM","03:15 AM","03:30 AM","03:45 AM",
                  "04:00 AM","04:15 AM","04:30 AM","04:45 AM",
                  "05:00 AM","05:15 AM","05:30 AM","05:45 AM",
                  "06:00 AM","06:15 AM","06:30 AM","06:45 AM",
                  "07:00 AM","07:15 AM","07:30 AM","07:45 AM",
                  "08:00 AM","08:15 AM","08:30 AM","08:45 AM",
                  "09:00 AM","09:15 AM","09:30 AM","09:45 AM",
                  "10:00 AM","10:15 AM","10:30 AM","10:45 AM",
                  "11:00 AM","11:15 AM","11:30 AM","11:45 AM",
                  "12:00 PM","12:15 PM","12:30 PM","12:45 PM",
                  "01:00 PM","01:15 PM","01:30 PM","01:45 PM",
                  "02:00 PM","02:15 PM","02:30 PM","02:45 PM",
                  "03:00 PM","03:15 PM","03:30 PM","03:45 PM",
                  "04:00 PM","04:15 PM","04:30 PM","04:45 PM",
                  "05:00 PM","05:15 PM","05:30 PM","05:45 PM",
                  "06:00 PM","06:15 PM","06:30 PM","06:45 PM",
                  "07:00 PM","07:15 PM","07:30 PM","07:45 PM",
                  "08:00 PM","08:15 PM","08:30 PM","08:45 PM",
                  "09:00 PM","09:15 PM","09:30 PM","09:45 PM",
                  "10:00 PM","10:15 PM","10:30 PM","10:45 PM",
                  "11:00 PM","11:15 PM","11:30 PM","11:45 PM",]
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    const auth = getAuth()
    window.addEventListener('resize', this.initTrHeight)

    // set what we think the current timezone is in.
    this.timeZoneList = moment.tz.names()

    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.userData.username = auth.currentUser.displayName
    if(this.userData.availability) this.daysOfWeek = this.userData.availability 
    this.selectedTimeZone = (this.userData.defaultTimeZone)?this.userData.defaultTimeZone:moment.tz.guess()

  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async updateAvailability(){
      const auth = getAuth()
      const db = getFirestore()

      if(this.errors){
        // Show error
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Availability Issues`,
            icon: 'CalendarIcon',
            variant: 'danger',
            text: 'Please check your availability for issues',
          },timeout: 5000
        })
        return
      }

      this.userData.defaultTimeZone = (this.selectedTimeZone)?this.selectedTimeZone:''
      await setDoc(doc(db, "profiles", auth.currentUser.displayName ), { defaultTimeZone:this.userData.defaultTimeZone, availability:this.daysOfWeek, availabilityUpdated:true }, { merge: true })
      this.userData.availability = this.daysOfWeek
      this.userData.availabilityUpdated = true

      // Update the local storage
      localStorage.setItem('userData', JSON.stringify(this.userData))

      // Go to search
      this.$router.replace('/app/search').then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Availability Updated',
            icon: 'CalendarIcon',
            variant: 'success',
          },
        })
      })

    },
    onChange(e) {
      this.checkIntervals()
    },
    checkIntervals(){
      this.errors = false
      this.daysOfWeek.forEach(row => { 
        let pastTime = "0:00 AM"
        for (const interval of row.intervals) {
          const isAfter = moment(pastTime, ["h:mm A"]).isAfter(moment(interval.startTime, ["h:mm A"]))
          if(isAfter){
            this.errors = true
            document.getElementById('intervalError-'+row.day).classList.remove("hidden")
          }else{
            pastTime = interval.endTime
            document.getElementById('intervalError-'+row.day).classList.add("hidden")
          } 
        }
      })
    },
    resetForm(){
      this.daysOfWeek = [{day:'SUN', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'MON', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'TUE', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'WED', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'THU', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'FRI', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]},
                  {day:'SAT', value:false, intervals:[{startTime:'9:00 AM' ,endTime:'5:00 PM'}]}]

      this.$nextTick(() => {
        this.setNewHeight()
        this.checkIntervals()
      })
    },
    copyDay(item){
      if(item.value){
        let setWeekday = this.daysOfWeek.find(days => days.day === this.copiedDay)
        if(setWeekday){
          setWeekday.value = true
          setWeekday.intervals = []
          item.intervals.forEach(interval => { 
            setWeekday.intervals.push({startTime:interval.startTime, endTime:interval.endTime}) //item.intervals
          });
          this.setNewHeight()
        } 
      }
    },
    repeateAgain(item) {
      if(item.value){
        item.intervals.push({startTime:'9:00 AM' ,endTime:'5:00 PM'})
      }
      else item.value = true

      this.setNewHeight()
    },
    removeItem(item, index) {
      if(item.intervals.length > 1) item.intervals.splice(index, 1)
      else item.value = false

      this.setNewHeight()
      this.checkIntervals()
    },
    setNewHeight(){
      this.$nextTick(() => {
        console.log(this.$refs.row[0].offsetHeight)
        let windowWidth = window.innerWidth
        if(windowWidth > 500 )
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        else 
          this.trAddHeight(this.$refs.row[0].offsetHeight + 60)
        this.checkIntervals()
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>